import { useEffect, useState } from 'react';

import { ConnectionStatus } from '@lp-lib/shared-schema';

import { ProfileIndex } from '../../../services/crowd-frames';
import { type TeamInviteNotification } from '../../../types/notification';
import { CrowdFramesAvatar } from '../../CrowdFrames';
import { XIcon } from '../../icons/XIcon';
import { useParticipantByClientId } from '../../Player';
import { useSoundEffect } from '../../SFX';
import { useIsTeamCaptainScribe, useTeamWithStaff } from '../../TeamAPI/TeamV1';
import { useJoinTeam } from '../../TeamAPI/TeamV1';
import { useMyClientId } from '../../Venue/VenuePlaygroundProvider';
import { useNotificationDataSource } from '../Context';
import { type BaseNotificationProps } from '../type';

const TeamInviteNotifcation = (
  props: BaseNotificationProps & {
    notification: TeamInviteNotification;
  }
): JSX.Element => {
  const clientId = useMyClientId();
  const metadata = props.notification.metadata;
  const inviterClientId = metadata.inviterClientId;
  const teamId = metadata.teamId;
  const inviter = useParticipantByClientId(inviterClientId);
  const team = useTeamWithStaff(teamId);
  const isInviterTeamLeader = useIsTeamCaptainScribe(teamId, inviterClientId);
  const [error, setError] = useState<string | null>(null);
  const { play: teamInviteSFX } = useSoundEffect('teamInvite');
  const joinTeam = useJoinTeam();
  const ns = useNotificationDataSource();

  useEffect(() => {
    teamInviteSFX();
  }, [teamInviteSFX]);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        ns.dismiss(props.notification.id);
      }, 5000);
    }
  }, [error, ns, props.notification.id]);

  const handleJoin = () => {
    if (!teamId) return;
    if (!team || team.membersCount === 0) {
      setError('Unable to join the team, Team no longer exists');
      return;
    }
    if (!isInviterTeamLeader) {
      setError('Unable to join the team, Invitation has expired');
      return;
    }
    if (team.isFull) {
      setError('Unable to join the team, Team is full');
      return;
    }
    if (!inviter || inviter.status !== ConnectionStatus.Connected) {
      setError('Unable to join the team, User is offline');
      return;
    }
    joinTeam({ teamId, debug: 'team-invite', memberId: clientId });
    handleClose();
  };

  const handleClose = () => {
    ns.dismiss(props.notification.id);
    if (props.closeToast) {
      props.closeToast();
    }
  };

  if (!inviter || !team) {
    return <></>;
  }
  return (
    <div className='flex flex-row items-center text-sm font-white p-3 w-100 rounded-xl bg-secondary'>
      <div className='relative flex-grow-0 flex-shrink-0 w-12 h-12'>
        <CrowdFramesAvatar
          participant={inviter}
          profileIndex={ProfileIndex.wh100x100fps8}
          enablePointerEvents={false}
        />
      </div>
      <div className='flex flex-col ml-2'>
        <div className='text-2xs text-white flex flex-row'>
          <div className='overflow-hidden max-w-20 truncate'>
            {inviter?.username}
          </div>
          <div>&nbsp;has invited you to a team:</div>
        </div>
        <div className='text-tertiary text-xs'>{team.name}</div>
        {error && <div className='text-red-003 text-2xs'>{error}</div>}
      </div>
      <div className='ml-auto' />
      {!error && (
        <div
          className='text-blue-004 cursor-pointer mr-10'
          onClick={handleJoin}
        >
          Join
        </div>
      )}
      <div className='cursor-pointer' onClick={handleClose}>
        <XIcon />
      </div>
    </div>
  );
};

// Required for lazy import
// eslint-disable-next-line import/no-default-export
export default TeamInviteNotifcation;
